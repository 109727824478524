import logo from "../../images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Img from "../InputComponents/Img";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import LanguageSwitcher from "../MainContext/LanguageSwitcher";
import { PrimaryContext } from "../MainContext/MainContext";
import axiosClient from "../../api/api";
import { BsDoorOpen } from "react-icons/bs";

const Header2 = ({ menuRefs }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [toggleInformation, setToggleInformation] = useState(false);
  const navigate = useNavigate();
  const { setUser, setToken, setIsAuthorized, isAuthorized } =
    useContext(PrimaryContext);

  const handleScrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("MGL_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div
      className="border-b border-[#FAFAFA] flex items-center justify-between gap-10 px-12 md:px-6 py-5
    md:hidden"
    >
      <Link to={"/"}>
        <Img src={logo} className="object-cover w-[120px] mr-5 sm:mr-0" />
      </Link>
      <div className="flex items-center gap-2">
        <LanguageSwitcher />
        {isAuthorized && (
          <div
            className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
            onClick={handleLogout}
          >
            <BsDoorOpen className="w-full h-full text-white" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header2;
