import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput, FileInput } from "../../inputComponents/inputcomp";
import defaultImg from "../../../img/profile.jpg";

//images
import logo from "../../../../images/logo.png";

const InnerCountries = () => {
  //get current office id from params
  const { countryId } = useParams();
  //new ad office data
  const [countriesData, setCountriesData] = useState({});
  //error
  const [error, setError] = useState(null);
  //updae res
  const [updateRes, setUpdateRes] = useState(null);

  //handle new admin
  const handleNewCountriesData = (value, inputName) => {
    setCountriesData((prev) => ({ ...prev, [inputName]: value }));
  };

  //ad office data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/countries/${countryId}`).then(({ data }) => {
        setCountriesData(data);
      });
    } catch (error) {
      setError(error);
    }
  }, [updateRes]);

  //change country data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    axiosAdmin
      .post(`/countries/${countryId}`, formData)
      .then((res) => {
        setUpdateRes(res);
        successToast("მონაცემები წარმატებით განახლდა!");
      })
      .catch((err) => {
        setError(err);
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  }

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          encType="multipart/form-data"
        >
          <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
            <div className="flex items-center justify-between flex-wrap gap-5">
              <div className="flex items-center gap-5 sm:gap-3">
                <div className="w-[100px] h-10">
                  <img src={logo} className="w-full h-full" />
                </div>
                <div>
                  <h2
                    className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                  >
                    ქვეყნის რედაქტირება
                  </h2>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
              >
                შენახვა
              </button>
            </div>

            <div className="flex gap-2">
              <img
                src={
                  countriesData.img !== ""
                    ? `${process.env.REACT_APP_API_URL}/storage/${countriesData.img}`
                    : defaultImg
                }
                className="w-28 h-28 rounded-lg mt-5"
              />
              <FileInput name={"ფოტოს ატვირთვა"} inputName={"img"} />
            </div>
            <div className="w-full pt-5">
              <TextInput
                name={"ქვეყანა"}
                inputName={"name"}
                inputSetter={handleNewCountriesData}
                value={countriesData.name}
              />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default InnerCountries;
