import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { SelectInputValueId, DateInput, TextInput } from "../../inputComponents/inputcomp";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

//images
import logo from '../../../../images/logo.png';

const InnerFlightsPage = () => {
    //get current user id 
    const { flightsId } = useParams();
    //new flights data
    const [flightsData, setFlightsData] = useState({});
    //error
    const [error, setError] = useState(null);
    //data from context
    const { abOffices } = useContext(AdminPrimaryContext);
    //office options
    const [officeOptions, setOfficeOptions] = useState([]);

    //handle new flights
    const handleNewFlightsData = (value, inputName) => {
        setFlightsData(prev => ({ ...prev, [inputName]: value }))
    }

    //add new flights 
    const handleEditFlights = async () => {
        try {
            const res = await axiosAdmin.put(`/shipments/${flightsId}`, flightsData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //flights data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/shipments/${flightsId}`).then(({ data }) => {
                setFlightsData(data);
            });
        } catch (error) {
            setError(error);
        }
    }, []);

    //country
    useEffect(() => {
        const tmp = abOffices.map((item) => {
            return { name: item.country, value: item.id }
        })
        setOfficeOptions(tmp);
    }, [abOffices]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-[100px] h-10">
                                <img src={logo} className="w-full h-full" />
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">რეისის რედაქტირება</h2>
                            </div>
                        </div>
                        <button
                            onClick={handleEditFlights}
                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                            შენახვა
                        </button>
                    </div>
                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                        <div className="w-6/12 sm:w-full md:w-full">
                            <SelectInputValueId
                                name={"ოფისი"}
                                inputName={"abroad_office_id"}
                                inputSetter={handleNewFlightsData}
                                options={officeOptions}
                                value={flightsData.abroad_office_id}
                            />
                            <SelectInputValueId
                                name={"სტატუსი"}
                                inputName={"status"}
                                inputSetter={handleNewFlightsData}
                                options={[
                                    {
                                        name: "არ დაწყებულა",
                                        value: 1
                                    },
                                    {
                                        name: "გზაშია",
                                        value: 2
                                    },
                                    {
                                        name: "ჩამოვიდა",
                                        value: 3
                                    },
                                ]}
                                value={flightsData.status}
                            />
                            <TextInput
                                name={"აღწერა"}
                                inputName={"description"}
                                inputSetter={handleNewFlightsData}
                                value={flightsData.description}
                            />
                            <DateInput
                                name={"ჩამოსვლის მიახლოებითი თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"estemated_date"}
                                value={flightsData.estemated_date}
                            />
                            <DateInput
                                name={"ფილიალებში გადანაწილების დაწყების თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"redistribution_at"}
                                value={flightsData.redistribution_at}
                            />
                        </div>
                        <div className="w-6/12 sm:w-full md:w-full">
                            <DateInput
                                name={"ჩამოსვლის თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"arrival_date"}
                                value={flightsData.arrival_date}
                            />
                            <DateInput
                                name={"გამოგზავნის თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"sent_at"}
                                value={flightsData.sent_at}
                            />
                            <DateInput
                                name={"ტერმინალური პროცედურების დაწყების თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"terminals_at"}
                                value={flightsData.terminals_at}
                            />
                            <DateInput
                                name={"საბაჟო პროცედურების დაწყების თარიღი"}
                                inputSetter={handleNewFlightsData}
                                inputName={"customs_at"}
                                value={flightsData.customs_at}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default InnerFlightsPage;