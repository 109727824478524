import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../../adminContext/AdminContext";
import WarehousePackageItem from "./WarehousePackageItem";
import {
  CheckBox,
  SelectInputValueId,
} from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import FilialsCardWar from "./FilialsCardWar";
import { useTranslation } from "react-i18next";
import PaginatedItems from "../../../Pagination/Pagination";
import { BiLoaderAlt } from "react-icons/bi";
import { BsX } from "react-icons/bs";

const WarehousePackage = () => {
  //translations
  const { t } = useTranslation();
  //user
  const { user } = useContext(AdminPrimaryContext);
  //current filial
  const [currentPlaceId, setCurrentPlaceId] = useState(0);
  //current place type
  const [currentPlaceType, setCurrentPlaceType] = useState(0);
  //branches data
  const { foreignAdresses } = useContext(AdminPrimaryContext);
  //package data
  const [packageData, setPackageData] = useState([]);
  //package dataErr
  const [packageDataErr, setPackageDataErr] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //search value and transportation
  const [search, setSearch] = useState({
    key: "",
  });
  //package check all
  const [checkAll, setCheckAll] = useState(false);
  //selected Packages
  const [selectedPackages, setSelectedPackages] = useState({
    pack_id: [],
  });
  //current page
  const [currentPage, setCurrentPage] = useState(1);
  //total count
  const [total, setTotal] = useState(0);
  //general shipment code for sending
  const [shipmentGeneralCode, setShipmentGeneralCode] = useState("");
  //is loading state
  const [isLoading, setIsLoading] = useState(false);
  //transportation and secured parcels
  const [transportation, setTransportation] = useState({
    transport: "",
    secured: "",
    repack: "",
  });
  //shipments
  const [shipments, setShipments] = useState([]);

  //handle checking
  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  //handle selected Packages
  const handleSelectedPackages = (value) => {
    if (!selectedPackages.pack_id.includes(value)) {
      setSelectedPackages((prev) => ({ pack_id: [...prev.pack_id, value] }));
    }
  };

  //handle delete selected Packages
  const handleDeleteSelectedPackages = (value) => {
    setSelectedPackages((prev) => ({
      pack_id: prev.pack_id.filter((obj) => {
        return obj !== value;
      }),
    }));
  };

  //handle change search value
  const handleSearchvalue = (e, inputName) => {
    setSearch((prev) => ({ ...prev, [inputName]: e.target.value }));
  };

  //handle transportation value
  const handleSearchTransport = (value, inputName) => {
    setTransportation((prev) => ({ ...prev, [inputName]: value }));
  };

  //current filial handler
  const handleCurrentPlaceId = (value) => {
    setCurrentPlaceId(value);
    setCheckAll(false);
    setSelectedPackages({
      pack_id: [],
    });
    const tmp = foreignAdresses.map((item) => {
      if (item.id === value) {
        setCurrentPlaceType(item.type);
        return null;
      } else {
        return null;
      }
    });
  };

  //handle delete package
  const handleDeletePackage = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/packs/${id}`);
      if (res) {
        setDifResponses(res);
        successToast(t("success"));
      }
    } catch (err) {
      setPackageDataErr(err);
      errorToast(t("error"));
    }
  };

  //const handle send package
  const handleSendSelectedPackages = async () => {
    try {
      const res = await axiosAdmin.post(`/packs/set`, {
        status: "2",
        ids: [...selectedPackages.pack_id],
        shipment_code: shipmentGeneralCode,
      });
      if (res) {
        successToast("ამანათები წარმატებით გაიგზავნა!");
        setCheckAll(false);
        setSelectedPackages({
          pack_id: [],
        });
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //data fetching
  const handleParcelInfo = () => {
    setIsLoading(true);
    axiosAdmin
      .get(
        `/packs?status=${1}&abroad_office_id=${currentPlaceId}&page=${
          currentPage + 1
        }` +
          `&per_page=${30}&addon_transp=${
            transportation.transport
          }&searchValue=${search.key}` +
          `&secured=${transportation.secured}` +
          `&repack=${
            transportation.repack === "false" ? "" : transportation.repack
          }`
      )
      .then(({ data }) => {
        setPackageData(data.data);
        setTotal(data.meta.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setPackageDataErr(err);
      });
  };

  //update user
  const handleGiveUserToParcel = (newData, packId) => {
    axiosAdmin
      .put(`/packs/${packId}`, {
        user_id: newData.userId,
        total_weight: newData.weight,
        shipment_code: newData.flightNumber,
        transport_price: newData.transport_price,
        dementionY: newData.dementionY,
        dementionZ: newData.dementionZ,
        dementionX: newData.dementionX,
      })
      .then((res) => {
        successToast("წარმატებით შეიცვალა!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  const handleUndeclared = (pack) => {
    axiosAdmin
      .get(`/packs/unDeclare/${pack}`)
      .then((res) => {
        successToast("მოქმედება წარმატებით შესრულდა!");
        setDifResponses(res);
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  //on barcode scanner using
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleParcelInfo();
    }
  };

  //send with flight number
  const handleSendWithFlight = () => {
    axiosAdmin
      .post("/packs/send", {
        shipment_code: shipmentGeneralCode,
        abroad_office_id: currentPlaceId,
      })
      .then((res) => {
        setDifResponses(res);
      });
  };

  //package data fetching
  useEffect(() => {
    handleParcelInfo();
  }, [currentPlaceId, difResponses, transportation, currentPage]);

  //package data fetching
  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  useEffect(() => {
    if (selectedPackages.pack_id.length > 0) {
      setCheckAll(false);
      setSelectedPackages({
        pack_id: [],
      });
    }
  }, [currentPage]);

  useEffect(() => {
    axiosAdmin
      .get(`/shipments?per_page=500`)
      .then(({ data }) => {
        setShipments((prev) => [
          ...data.data.map((item) => {
            return {
              value: item.code,
              name: item.code,
            };
          }),
        ]);
      })
      .catch((err) => {});
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full overflow-auto scrollbar mb-5">
        <div className="flex gap-1 w-full md:w-[1000px] p-2">
          {foreignAdresses.map((item) => {
            if (user.role === "100") {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === "1" && item.id === 9) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === "2" && item.id === 6) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
            if (user.role === "3" && item.id === 5) {
              return (
                <FilialsCardWar
                  item={item}
                  key={item.id}
                  currentPlaceId={currentPlaceId}
                  handleCurrentPlaceId={handleCurrentPlaceId}
                  setDifResponses={setDifResponses}
                />
              );
            }
          })}
        </div>
      </div>
      <h2 className="text-slate-400 text-2xl">საწყობში მისული ამანათები</h2>
      <div className="w-full">
        <div className="bg-yellow mt-2 flex gap-1 sm:flex-col">
          <div
            className="bg-white  pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-9/12 sm:w-full"
          >
            <input
              type="search"
              placeholder={t("search")}
              onChange={(e) => {
                handleSearchvalue(e, "key");
              }}
              className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
              onKeyDown={handleKeyPress}
            />
          </div>

          <SelectInputValueId
            options={shipments}
            inputName={"shipmentId"}
            inputSetter={(flight_num, _) => {
              setShipmentGeneralCode(flight_num);
            }}
            value={shipmentGeneralCode}
            defaultText={"საერთო რეისის ნომერი(სურვილისამებრ)"}
            needClear={true}
          />

          <Link
            to={
              `${process.env.REACT_APP_API_URL}/api/exportPacks?status=${1}` +
              `&abroad_office_id=${currentPlaceId}` +
              `&repack=${
                transportation.repack === "false" ? "" : transportation.repack
              }`
            }
            className="w-3/12 rounded-lg bg-green-400 sm:w-full flex items-center justify-center p-1 py-3 text-white"
          >
            EXPORT CSV
          </Link>
        </div>
        <div className="flex items-center justify-between my-2 flex-wrap gap-3 mt-2">
          <div className="flex gap-2 items-center sm:order-2 sm:flex-col sm:items-start">
            <CheckBox
              name={"ტრანსპორტირება"}
              inputName={"transport"}
              handleCarData={handleSearchTransport}
            />
            <CheckBox
              name={"დაზღვეული ამანათები"}
              inputName={"secured"}
              handleCarData={handleSearchTransport}
            />
            <div className="flex items-center gap-3">
              <input
                checked={checkAll}
                onChange={(e) => {
                  handleCheckAll(e);
                }}
                type="checkbox"
              />
              <p className="text-sm text-gray-500">ყველას მონიშვნა</p>
            </div>
            <CheckBox
              name={"გადაფუთული ამანათები"}
              inputName={"repack"}
              handleCarData={handleSearchTransport}
            />
          </div>
          <div className="flex gap-2 sm:flex-wrap">
            <div
              onClick={handleSendSelectedPackages}
              className="rounded-lg bg-green-400 sm:w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
            >
              <p>მონიშნულის გამოგზავნა</p>
            </div>
          </div>
          <div className="flex gap-2 sm:flex-wrap">
            <div
              onClick={handleSendWithFlight}
              className="rounded-lg bg-green-400 sm:w-full cursor-pointer
                 flex items-center justify-center gap-1 p-1 py-3 text-white"
            >
              <p>რეისით გამოგზავნა</p>
            </div>
          </div>
        </div>
        <div className="w-full flex gap-3 flex-wrap mt-5">
          <PaginatedItems pageNumberSetter={setCurrentPage} dataLength={total}>
            {isLoading ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <BiLoaderAlt className="w-20 h-20 animate-spin text-green-500" />
              </div>
            ) : isLoading === false && packageData.length === 0 ? (
              <div className="mt-5 w-full flex items-center justify-center min-h-[200px]">
                <p className="text-slate-600 text-sm">მონაცემები არ მოიძებნა</p>
              </div>
            ) : (
              <div className="w-full flex flex-col gap-2">
                {packageData.map((item) => {
                  return (
                    <WarehousePackageItem
                      key={item.id}
                      data={item}
                      handleDeletePackage={handleDeletePackage}
                      setCheckedAll={checkAll}
                      setDifResponses={setDifResponses}
                      handleSelectedPackages={handleSelectedPackages}
                      handleDeleteSelectedPackages={
                        handleDeleteSelectedPackages
                      }
                      handleGiveUserToParcel={handleGiveUserToParcel}
                      handleUndeclared={handleUndeclared}
                      currentPlaceType={currentPlaceType}
                    />
                  );
                })}
              </div>
            )}
          </PaginatedItems>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default WarehousePackage;
