import {
  BsBox,
  BsPiggyBank,
  BsBoxSeam,
  BsFolder,
  BsShield,
  BsEnvelope,
  BsCompass,
  BsGear,
} from "react-icons/bs";
import { BiWallet, BiStar, BiMap } from "react-icons/bi";
import { TbPlaneTilt } from "react-icons/tb";
import { FaBarcode, FaRegFile } from "react-icons/fa";

const menuData = [
  {
    title: "balance",
    menuItem: [
      {
        title: "my_balance",
        link: "my-ballance",
        Icon: BiWallet,
      },
      {
        title: "balance_top_up",
        link: "ballance-top-up",
        Icon: BsPiggyBank,
      },
      {
        title: "bonus_points",
        link: "bonus-points",
        Icon: BiStar,
      },
    ],
  },
  {
    title: "parcels",
    menuItem: [
      {
        title: "my_parcels",
        link: "my-parcels",
        Icon: BsBoxSeam,
      },
      {
        title: "unclear_parcels",
        link: "unclear-parcels",
        Icon: BsBox,
      },
      {
        title: "declarationPrices",
        link: "declaration-prices",
        Icon: FaRegFile,
      },
    ],
  },
  {
    title: "addresses/flights",
    menuItem: [
      {
        title: "addresses",
        link: "addresses",
        Icon: BiMap,
      },
      {
        title: "flights",
        link: "panel-flights",
        Icon: TbPlaneTilt,
      },
    ],
  },
  {
    title: "reports",
    menuItem: [
      {
        title: "transaction_history",
        link: "transactions",
        Icon: BsFolder,
      },
      {
        title: "insurance",
        link: "insurance",
        Icon: BsShield,
      },
    ],
  },
  {
    title: "settings/support",
    menuItem: [
      {
        title: "messages",
        link: "messeges",
        Icon: BsEnvelope,
      },
      {
        title: "support",
        link: "support",
        Icon: BsCompass,
      },
      {
        title: "settings",
        link: "settings",
        Icon: BsGear,
      },
    ],
  },
];

export default menuData;
