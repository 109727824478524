import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Power3 } from "gsap";
import axiosAdmin from "../../api/apiAdmin";
import { slideEffect } from "./animations/animations";
import { AdminPrimaryContext } from "./adminContext/AdminContext";
import { useTranslation } from "react-i18next";

//icons
import {
  SunFill,
  MoonFill,
  List,
  ChevronLeft,
  Plus,
  Dash,
  DoorOpenFill,
} from "react-bootstrap-icons";
import { BsPersonCircle } from "react-icons/bs";
function FullNavigation() {
  //for navigation
  const navigate = useNavigate();
  //toggle left sidebar
  const [sideBarLeftOpen, setSideBarLeftOpen] = useState(false);
  //data and functions from context
  const { user, setToken, setIsAuthorized, setUser } =
    useContext(AdminPrimaryContext);

  //hanle left sidebar toggler
  const synchronizeLeftState = () => {
    setSideBarLeftOpen(!sideBarLeftOpen);
  };

  //dark theme controll
  const [theme, setTheme] = useState(null);
  const [changeSunIcon, setChangeSunIcon] = useState(false);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
    setChangeSunIcon(!changeSunIcon);
  };

  // left nav bar animation
  const [myElement, setMyElement] = useState(null);
  const handleElementChange = (newElement) => {
    setMyElement(newElement);
  };
  // right nav bar animation
  const time = 0.5;
  const effectLeftToRightShow = {
    x: 0,
    ease: Power3.easeOut,
  };

  //log out
  const logOut = async () => {
    try {
      const res = await axiosAdmin.get("/logout");
      if (res) {
        setIsAuthorized(false);
        localStorage.removeItem("MGL_TOKEN_ADM");
        setUser({});
        navigate("/admin/login");
      }
    } catch (err) {}
  };

  return (
    <>
      <section
        className="fixed bg-white top-0 left-0 w-full h-14 flex items-center justify-between z-40
                border-b border-solid border-neutral-300 py-3.5 px-10 md:px-4 sm:px-4 dark:bg-slate-800 dark:border-slate-600"
      >
        <div
          onClick={() => {
            setSideBarLeftOpen(!sideBarLeftOpen);
          }}
          className="flex items-center justify-center"
        >
          <div
            onClick={() => {
              slideEffect(myElement, time, effectLeftToRightShow);
              synchronizeLeftState();
            }}
          >
            <List
              size={30}
              className="text-[#4f46e5] dark:text-white cursor-pointer"
            />
          </div>
        </div>
        <div className="flex items-center justify-center gap-2.5">
          <div className="flex gap-2.5">
            <div
              onClick={handleThemeSwitch}
              className="flex items-center justify-center hover:rounded-full hover:bg-gray-100 w-8 h-8
                        cursor-pointer"
            >
              {changeSunIcon ? (
                <MoonFill size={18} className="text-yellow-300" />
              ) : (
                <SunFill size={18} className="text-yellow-300" />
              )}
            </div>
            <div
              onClick={logOut}
              className="flex items-center justify-center hover:rounded-full hover:bg-gray-100 w-8 h-8
                            cursor-pointer"
            >
              <DoorOpenFill
                size={18}
                className="text-gray-500 dark:text-white"
              />
            </div>
            <Link
              to={"admin-page"}
              className="flex items-center justify-center  bg-gray-100 h-8 dark:bg-slate-600
                            cursor-pointer px-2 rounded-lg gap-1"
            >
              <BsPersonCircle
                size={18}
                className="text-gray-500 dark:text-white"
              />
              <p className="text-gray-500 dark:text-white text-[12px]">
                {user.name}
              </p>
            </Link>
          </div>
        </div>
      </section>
      <LeftSideToggleNavigation
        isOpen={sideBarLeftOpen}
        syncState={synchronizeLeftState}
        handleElementChange={handleElementChange}
      />
    </>
  );
}

const DropDownMenuItem = ({
  outerName,
  outerLink,
  innerLinkObj,
  line,
  lineNum,
  setCurrentPage,
  currentPage,
}) => {
  //location
  const location = useLocation();
  //current selected part
  const [currentSubPage, setCurrentSubPage] = useState(() => {
    return location.pathname.split("/").pop();
  });
  let [isDropped, setIsDropped] = useState(true);

  let element = useRef();
  let time = 0.5;
  let slideDown = {
    maxHeight: "9999px",
    ease: Power3.easeIn,
  };
  let slideUp = {
    maxHeight: "0",
    ease: Power3.easeOut,
  };

  return (
    <div>
      <Link
        to={innerLinkObj == "" ? outerLink : "#"}
        className={`w-60 p-2 rounded-lg block ${
          currentPage === outerLink && outerLink !== ""
            ? "bg-slate-300"
            : "hover:bg-slate-300"
        }`}
        onClick={() => {
          setIsDropped(!isDropped);
          isDropped
            ? slideEffect(element, time, slideDown)
            : slideEffect(element, time, slideUp);
          setCurrentPage(outerLink);
        }}
      >
        <div className="flex justify-between items-center">
          <p className="text-sm text-gray-500 font-bold dark:text-gray-400 ">
            {outerName}
          </p>
          <>
            {!(innerLinkObj == "") ? (
              isDropped ? (
                <Plus className="text-sm text-gray-500  font-bold dark:text-gray-400" />
              ) : (
                <Dash className="text-sm text-gray-500  font-bold dark:text-gray-400" />
              )
            ) : (
              <div></div>
            )}
          </>
        </div>
      </Link>
      <>
        {!(innerLinkObj == "") ? (
          <div
            ref={(el) => {
              element = el;
            }}
            className="max-h-0 overflow-hidden"
          >
            {innerLinkObj.map((singleObject, index) => {
              return (
                <div
                  key={index}
                  className="last:border-slate-300 last:border-b rounded-lg "
                >
                  <Link
                    to={singleObject.link}
                    onClick={() => {
                      setCurrentSubPage(singleObject.link);
                    }}
                    className={`w-60 p-2 pl-3 block rounded-lg ${
                      currentSubPage === singleObject.link
                        ? "bg-slate-100 "
                        : "hover:bg-slate-100 "
                    }`}
                  >
                    <p className="indent-2 text-sm text-gray-500 font-bold dark:text-gray-400">
                      {singleObject.name}
                    </p>
                  </Link>
                  {line && lineNum === index && (
                    <div className="h-[1px] bg-gray-300 w-full px-2"></div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

function LeftSideToggleNavigation(props) {
  //translations
  const { t } = useTranslation();
  //path
  const location = useLocation();
  //current page
  const [currentPage, setCurrentPage] = useState(() => {
    return location.pathname.split("/").pop();
  });
  const [isOpen, setIsopen] = useState(props.isOpen);
  useEffect(() => {
    setIsopen(props.isOpen);
  }, [props.isOpen]);

  let slidableElement = useRef(null);
  let time2 = 0.5;
  let effectSlideRightToLeft = {
    x: "-100%",
    ease: Power3.easeOut,
  };
  useEffect(() => {
    props.handleElementChange(slidableElement);
  }, [isOpen]);

  const { user } = useContext(AdminPrimaryContext);

  return (
    <section
      ref={(el) => {
        slidableElement = el;
      }}
      className="flex fixed top-0 left-0 z-50 lg:translate-x-[-100%] md:translate-x-[-100%] sm:translate-x-[-100%]"
    >
      <div>
        <div
          className=" flex flex-col bg-white  dark:bg-slate-800
                    z-50 h-screen border-r border-neutral-30 py-2 px-5 dark:border-slate-600"
        >
          <div className="flex justify-between items-center border-b  border-neutral-30 pb-2 dark:border-slate-600">
            <div className="w-full flex justify-between items-center dark:border-slate-500">
              <div className="flex gap-2 items-center">
                <p className="indent-2 text-sm text-gray-500 font-bold dark:text-gray-400">
                  CMS
                </p>
              </div>
              <div className="xl:hidden ">
                <ChevronLeft
                  onClick={() => {
                    slideEffect(slidableElement, time2, effectSlideRightToLeft);
                  }}
                  className="text-md text-gray-600 dark:text-gray-400 cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3.5 relative overflow-y-auto  scrollbar">
            <div className="flex flex-col gap-1 pr-2 ">
              {user.role === "100" && (
                <>
                  <DropDownMenuItem
                    outerName={"მომხმარებლები"}
                    outerLink={"users"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"ადმინისრატორები"}
                    outerLink={"admins"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"ქვეყნები"}
                    outerLink={"countries"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"უცხოეთის საწყობი"}
                    outerLink={"ab-office"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"ფილიალები"}
                    outerLink={"filial"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"რეისები"}
                    outerLink={"flights"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"ამანათები"}
                    outerLink={""}
                    line={true}
                    lineNum={null}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    innerLinkObj={[
                      {
                        name: "საწყობში მისული",
                        link: "in-warehouse",
                      },
                      {
                        name: "გამოგზავნილი",
                        link: "sent",
                      },
                      {
                        name: "ჩამოსული",
                        link: "appeard",
                      },
                      {
                        name: "ჩაბარებული",
                        link: "deliverd",
                      },
                    ]}
                  />
                  <DropDownMenuItem
                    outerName={"საფორთი"}
                    outerLink={"support"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"მესიჯები"}
                    outerLink={"messages"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <DropDownMenuItem
                    outerName={"დეკლარაციის სერვისი"}
                    outerLink={"declaration-service"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                </>
              )}
              {(user.role === "1" ||
                user.role === "2" ||
                user.role === "3") && (
                <>
                  <DropDownMenuItem
                    outerName={"Parcels"}
                    outerLink={"in-warehouse"}
                    innerLinkObj={""}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FullNavigation;
