import { FaFacebook, FaInstagram } from "react-icons/fa";
import Img from "../InputComponents/Img";
import mglLogo from "../../images/logo.png";
import { useTranslation } from "react-i18next";

const Footer2 = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-[#FAFAFA] p-5 flex items-center justify-center px-12 md:px-6">
        <div className="flex flex-col gap-2 sm:hidden">
          <Img
            src={mglLogo}
            className="w-[120px] md:flex-1 sm:h-auto md:mt-0 mt-1 object-cover"
            alt="#"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer2;
