import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { BsDoorOpen, BsList } from "react-icons/bs";
import { BiChevronLeft } from "react-icons/bi";
import logo from "../../images/logo.png";
import LanguageSwitcher from "../MainContext/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../MainContext/MainContext";

const HeaderPhone = ({ menuRefs }) => {
  //translation
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);

  const navigate = useNavigate();

  const { setUser, setToken, setIsAuthorized, isAuthorized } =
    useContext(PrimaryContext);

  //currentPage Handler
  const handleCurrentPage = () => {
    setToggleMenu((prev) => !prev);
  };

  const handleScrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("MGL_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div className="afMd:hidden">
      <div className="w-full p-5 justify-between items-center flex bg-white border-b-2 border-[#F0F0F0]">
        <Link
          to={"/"}
          onClick={() => {
            setToggleMenu(false);
          }}
        >
          <img src={logo} alt="logo" className="w-[100px]" />
        </Link>
        <div className="flex items-center gap-2">
          <LanguageSwitcher />

          {isAuthorized && (
            <div
              className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
              onClick={handleLogout}
            >
              <BsDoorOpen className="w-full h-full text-white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderPhone;
