import Line from "../../components/InputComponents/Line";
import { useEffect, useState } from "react";
//icons
import { BsFileCheck, BsFileText, BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DeclarationDetails = ({ setDecShow, data }) => {
  const { t } = useTranslation();
  //addon security
  const [addon, setAddon] = useState(false);

  useEffect(() => {
    if (data.addon_secur === 1) {
      setAddon(true);
    } else {
      setAddon(false);
    }
  }, [data]);

  return (
    <div
      className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
    >
      <div
        className="bg-white flex flex-col w-[50%] md:w-[80%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative"
      >
        <BsX
          onClick={() => {
            setDecShow(false);
          }}
          className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
        />
        <div className="flex flex-col gap-2 items-center justify-center w-full">
          <Line className="bg-teal-600 h-0.5 w-20" />
          <h2 className="text-center font-semibold w-auto text-xl">
            {t("declaration_details")}
          </h2>
        </div>
        <Line className=" bg-gray_300 h-0.5 w-full my-5" />
        <div className="w-full overflow-auto scrollbar">
          <div className="flex flex-col gap-2 w-full md:h-[80vh] sm:h-[50vh]">
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 w-full">
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("tracking_number")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.tracking_number}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("price")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.real_price} {data.real_price_currency}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("website")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.sender_name}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("deliver_address")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.addon_transp}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {" "}
                  {t("description")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.comment}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("security")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {addon ? "Yes" : "No"}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("trustee")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.another_person}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("office")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.office !== null && data.office.name}
                </div>
              </div>
              <div className="w-full">
                <p className="text-gray-500 text-sm sm:text-xs mb-2">
                  {t("deliver_address")}
                </p>
                <div
                  className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
                >
                  {data.addon_transp}
                </div>
              </div>
              {data?.invoice && (
                <div className="w-full">
                  <p className="text-gray-500 text-sm sm:text-xs mb-2">
                    {t("invoice_details")}
                  </p>
                  <Link
                    to={`${process.env.REACT_APP_API_URL}/storage/${data?.invoice}`}
                    target="_blank"
                    className="items-center flex gap-4 bg-gray_50 border-2 border-gray_300 cursor-pointer
                border-solid font-normal h-12 w-12 px-2 py-2 rounded-[12px] text-gray_600 text-left"
                  >
                    <BsFileText className="w-full h-full text-teal_600" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclarationDetails;
