import Line from "../../components/InputComponents/Line";
import TextInput from "../../components/InputComponents/TextInput";
import { useContext, useEffect, useState } from "react";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import SelectInput from "../../components/InputComponents/SelectInput";
import { ToastContainer } from "react-toastify";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
//icons
import { BsX } from "react-icons/bs";
import FileInput from "../../components/InputComponents/FileInput";
import { useTranslation } from "react-i18next";
import useCurrencyChanger from "../../components/customHooks/useCurrencyChanger";

const Declaration = ({ setDecShow, data, setUpdatePacks }) => {
  const { t } = useTranslation();
  //data from context
  const { currency, filials } = useContext(PrimaryContext);
  //currency options
  const [currencyOptions, setCurrencyOptions] = useState([]);
  //selected Data
  const [selectedData, setSelectedData] = useState({
    currency: "USD",
  });
  //personal value
  const [isPersonal, setIsPersonal] = useState(false);
  //file selected
  const [isFileSelected, setIsFileSelected] = useState("");

  let currencyRate = useCurrencyChanger({ curr: selectedData.currency });

  const handleGetCurrency = (id, inputName) => {
    setSelectedData((prev) => ({
      ...prev,
      [inputName]: currency.filter((item) => {
        return id === item.id && item;
      })[0].currency,
    }));
  };

  const handleGetValues = (value, inputName) => {
    setSelectedData((prev) => ({ ...prev, [inputName]: value }));
  };

  useEffect(() => {
    if (data.country !== null && data.country.id === 4) {
      const currs = currency
        .map((item) => {
          if (item.currency === "USD" || item.currency === "TRY") {
            return { id: item.id, title: item.currency };
          }
          return null; // Return null for items that don't match the condition
        })
        .filter(Boolean);
      setCurrencyOptions(currs);
    } else if (data.country !== null && data.country.id === 3) {
      const currs = currency
        .map((item) => {
          if (item.currency === "USD" || item.currency === "CNY") {
            return { id: item.id, title: item.currency };
          }
          return null; // Return null for items that don't match the condition
        })
        .filter(Boolean);
      setCurrencyOptions(currs);
    } else {
      setCurrencyOptions(
        currency.map((item) => {
          return { id: item.id, title: item.currency };
        })
      );
    }
  }, [currency]);

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const finalPrice = parseFloat(selectedData?.value) * currencyRate;
    if (parseInt(data.total_weight) > 30 && isFileSelected === "") {
      errorToast(t("more_than_30"));
      errorToast(t("uploadInvoice"));
    } else if (finalPrice > 250 && isFileSelected === "") {
      errorToast(t("more_than_250"));
      errorToast(t("uploadInvoice"));
    } else if (parseInt(selectedData.amount) > 4 && isFileSelected === "") {
      errorToast(t("more_than_4"));
      errorToast(t("uploadInvoice"));
    } else {
      formData.append("currency", selectedData.currency);
      formData.append("office_id", selectedData.office_id);
      formData.append(
        "items",
        JSON.stringify([
          { price: selectedData.value, name: selectedData.amount },
        ])
      );

      const declareFunc = () => {
        axiosClient
          .post(`/updateWithItems/${data.id}`, formData)
          .then((res) => {
            setUpdatePacks(res);
            successToast("successful");
          })
          .catch(() => {
            errorToast("error");
          });
      };

      const personalValidation =
        !isPersonal &&
        selectedData.sender_name !== undefined &&
        selectedData.sender_name !== "";
      const commentValidation =
        selectedData.comment !== undefined && selectedData.comment !== "";

      if (personalValidation && commentValidation) {
        declareFunc();
      } else if (commentValidation) {
        declareFunc();
      }
    }
  }

  return (
    <div
      className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
    >
      <div
        className="bg-white flex flex-col w-[50%] md:w-[90%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative"
      >
        <BsX
          onClick={() => {
            setDecShow(false);
          }}
          className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
        />
        <div className="flex flex-col gap-2 items-center justify-center w-full">
          <Line className="bg-teal-600 h-0.5 w-20" />
          <h2 className="text-center font-semibold w-auto text-xl">
            {t("declare")}
          </h2>
        </div>
        <Line className=" bg-gray_300 h-0.5 w-full my-5" />
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full overflow-auto scrollbar"
        >
          <div className="flex flex-col gap-2 w-full md:h-[80vh] sm:h-[50vh]">
            <p className="font-bold text-left text-teal_600 w-auto text-sm sm:text-xs">
              {t("tracking_number")} #: {data.tracking_number}
            </p>
            <TextInput
              name={t("price")}
              inputName={"value"}
              inputSetter={handleGetValues}
              pattern={"noZero"}
              type="number"
            />
            <SelectInput
              name={t("currency")}
              inputName={"currency"}
              options={currencyOptions}
              inputSetter={handleGetCurrency}
            />
            <div className="flex gap-2 items-center">
              <input
                id="security"
                type="checkbox"
                name="sender_type"
                onChange={(e) => {
                  setIsPersonal(e.target.checked);
                }}
              />
              <label
                htmlFor="security"
                className="text-gray-500 text-sm sm:text-xs"
              >
                {t("personal_parcel")}
              </label>
            </div>
            <TextInput
              name={t("website")}
              pattern={"num-en"}
              inputName={"sender_name"}
              inputSetter={handleGetValues}
              required={!isPersonal}
            />
            <SelectInput
              name={t("office")}
              inputName={"office_id"}
              options={filials}
              inputSetter={handleGetValues}
            />
            <TextInput
              name={t("description")}
              inputName={"comment"}
              pattern={"ge"}
              placeholder={t("only_georgian")}
              inputSetter={handleGetValues}
              required={true}
            />
            <TextInput
              name={t("parcel_amount")}
              inputName={"amount"}
              inputSetter={handleGetValues}
            />
            <div className="grid grid-cols-3 gap-2 md:grid-cols-1">
              <div className="flex gap-2 items-center">
                <input id="security" type="checkbox" name="addon_secur" />
                <label
                  htmlFor="security"
                  className="text-gray-500 text-sm sm:text-xs"
                >
                  {t("addon_security")}
                </label>
              </div>
              <FileInput
                name={t("upload_invoice")}
                inputName={"invoice"}
                setIsFileSelected={setIsFileSelected}
              />

              <button
                className="bg-teal_600_b2 border-2 border-solid border-teal_600 flex items-center justify-center px-[7px]
                            rounded-[12px] h-12 cursor-pointer w-full"
                type="submit"
              >
                <p className="text-white text-sm sm:text-xs">{t("save")}</p>
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Declaration;
