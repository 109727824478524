import React from "react";
import logo from "../../../images/logo.png";
import { FaRegCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import wayImg from "../../../images/constants/reg-success-line.png"

const RegistrationSuccessful = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirection = () => {
    navigate("/personal-cabinet/my-ballance");
  };
  return (
    <div
      className="w-screen h-screen reg-success-bg bg-center bg-no-repeat bg-cover fixed top-0 left-0
  flex items-center justify-center"
    >
      <img src={wayImg} className="absolute bottom-0 right-0 h-full sm:h-auto z-40"/>
      <div
        className="bg-white rounded-lg flex flex-col gap-10 items-center justify-center p-20
    md:p-10 sm:p-5 relative z-50"
      >
        <img src={logo} alt="#" className="w-[140px]" />

        <FaRegCheckCircle className="text-teal_600 w-[60px] h-[60px]" />

        <h2 className="text-2xl text-center sm:text-lg">
          {t("registration_successful")}
        </h2>

        <div
          className="bg-teal_600 px-8  py-3 rounded-[6px] cursor-pointer"
          onClick={handleRedirection}
        >
          <p className="text-sm text-white">{t("userCabinet")}</p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessful;
