import Line from "../../components/InputComponents/Line";
import Button from "../../components/InputComponents/Button";
import { Link } from "react-router-dom";
import { BiPaperPlane, BiSearch } from "react-icons/bi";
import { useContext, useEffect, useState } from "react";
import axiosClient from "../../api/api";
import UserPagination from "../../components/UserPagination/UserPagination";
import { BiLoaderAlt } from "react-icons/bi";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";

const Messeges = () => {
  const { t } = useTranslation();
  //messages
  const [messages, setMessages] = useState([]);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //message type
  const [type, setType] = useState(1);
  //context data
  const { user } = useContext(PrimaryContext);
  //search value
  const [searchValue, setSearchValue] = useState("");
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(
        `/message?page=${
          currentPage + 1
        }&per_page=${12}&type=${type}&searchValue=${searchValue}`
      )
      .then(({ data }) => {
        setMessages(data.data);
        setTotalAmount(data.total);
        setIsLoading(false);
      });
  }, [currentPage, type, searchValue]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("messages")}</h2>
      </div>
      <div className="flex gap-2 mt-5">
        <div className="w-full flex items-center gap-2 sm:flex-col">
          <div
            className="flex items-center gap-4 w-full bg-white shadow-bs1
                font-normal h-12 md:max-w-full px-2 py-2 rounded-[12px] text-gray_600 text-left"
          >
            <input
              placeholder={t("type_word_here")}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              className="bg-transparent w-full"
            />
            <div className="w-10 h-10 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600">
              <BiSearch className="w-full h-full text-white" />
            </div>
          </div>
          <Link to={"../assistance"} className="sm:w-full">
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[310px] w-auto sm:w-full sm:min-w-full"
              leftIcon={<BiPaperPlane className="text-white w-6 h-6" />}
              shape="RoundedBorder14"
              size="lg"
              variant="OutlineTeal600"
            >
              <div className="font-normal text-md text-center text-white_A700">
                {t("create_new_messages")}
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex gap-2 items-center mt-5">
        <div className="flex gap-2 items-center sm:w-full sm:justify-center sm:items-center">
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="my"
              name="type"
              value={"1"}
              onChange={(e) => {
                setType(e.target.value);
              }}
              checked={type === "1"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"all"}
              className="font-semibold cursor-pointer text-left text-sm"
            >
              {t("my_messages")}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="received"
              name="type"
              value={"2"}
              onChange={(e) => {
                setType(e.target.value);
              }}
              checked={type === "2"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"land"}
              className="font-semibold cursor-pointer text-left text-sm"
            >
              {t("received_messages")}
            </label>
          </div>
        </div>
      </div>
      <UserPagination
        dataLength={totalAmount}
        itemsPerPage={12}
        pageNumberSetter={setCurrentPage}
      >
        <div className="shadow-bs1 rounded-[30px] my-5 bg-white sm:rounded-lg overflow-hidden">
          {!isLoading && messages?.length !== 0 && (
            <div className="p-4 px-5">
              <div>
                <p className="font-semibold text-base md:text-sm">
                  {t("my_messages")}
                </p>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="grid grid-cols-1">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className={`animate-pulse bg-gray-200 w-full h-16 border-t border-white ${
                      index === 0 && "md:border-t-0"
                    } `}
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : messages?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <div className="flex flex-col">
              {messages.map((item) => {
                return (
                  <div
                    className={`p-4 px-5 border-t border-gray-200 flex items-center justify-between
                                    ${
                                      type === "2" &&
                                      item.seen !== 1 &&
                                      "bg-green-100"
                                    }`}
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <Button
                        className="h-[51px] w-[51px] cursor-pointer font-bold text-[22px] text-center text-white_A700 "
                        shape="RoundedBorder14"
                        size="md"
                        variant="FillGray300"
                      >
                        {type === "2"
                          ? "M"
                          : user.fname_eng && user.fname_eng.charAt(0)}
                      </Button>
                      <div className="flex flex-col gap-1">
                        <p className="text-teal_600 text-sm font-semibold">
                          From: {type === "2" ? "Parcelio" : user.fname_eng}
                        </p>
                        <p className="text-gray-500 text-xs font-semibold">
                          {item.message}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`../messeges/${item.id}`}
                      className="flex items-center justify-end"
                    >
                      <Button
                        className="cursor-pointer font-normal min-w-[96px]
                                     text-sm text-center text-white_A700 w-auto"
                        shape="RoundedBorder11"
                        size="md"
                        variant="OutlineTeal600"
                      >
                        {t("see_more")}
                      </Button>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </UserPagination>
    </div>
  );
};

export default Messeges;
