import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { PrimaryContext } from "../MainContext/MainContext";
import terms from "../../termss.pdf";
import axiosClient from "../../api/api";
import menuData from "./menuData";

//icons
import { BsList, BsBox, BsDoorOpen } from "react-icons/bs";
import { BiChevronLeft, BiWallet } from "react-icons/bi";
import { useTranslation } from "react-i18next";

// images
import logo from "../../images/logo.png";
import LanguageSwitcher from "../MainContext/LanguageSwitcher";
import Messenger from "../Messenger/Messnger";

const UserMenuPhone = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpend, setMenuOpend] = useState(false);

  const { user, setUser, setToken, setIsAuthorized, isAuthorized, dept } =
    useContext(PrimaryContext);
  const [data, setData] = useState(menuData);

  useEffect(() => {
    setData((prev) =>
      prev.map((item) => {
        return {
          title: item.title,
          menuItem: item.menuItem.map((item2) => {
            let active = false;
            if (item2.link === location.pathname.split("/")[2]) {
              active = true;
            } else {
              active = false;
            }
            return {
              ...item2,
              active: active,
            };
          }),
        };
      })
    );
  }, [location]);

  //handle logout
  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div>
      <div className="w-full p-5 justify-between items-center flex bg-white">
        <Link to={"/"}>
          <img src={logo} alt="logo" className="w-[100px]" />
        </Link>
        <div className="flex items-center gap-2">
          <LanguageSwitcher />
          {isAuthorized && (
            <div
              className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
              onClick={handleLogout}
            >
              <BsDoorOpen className="w-full h-full text-white" />
            </div>
          )}

          <div
            className="w-[30px] h-[30px] bg-teal_600 p-[6px] rounded-full cursor-pointer"
            onClick={() => {
              setMenuOpend(true);
            }}
          >
            <BsList className="w-full h-full text-white" />
          </div>
        </div>
      </div>

      <div className="w-full p-3">
        <div className="w-full p-2 justify-between items-center flex bg-white rounded-lg shadow-bs1">
          <div className="flex gap-1 items-center">
            <div className="flex flex-col items-start justify-start w-auto">
              <p className="text-xs font-semibold">
                {user.company_id === null ? (
                  <>
                    {user.fname_eng} {user.lname_eng} ID: {user.id}
                  </>
                ) : (
                  <>
                    {user.cname_eng} ID: {user.id}
                  </>
                )}
              </p>
              <div className="flex gap-2 items-center">
                <p className="text-xs text-teal_600 font-semibold">
                  {user.balance !== null &&
                    user.balance !== undefined &&
                    user.balance.toFixed(2)}{" "}
                  GEL
                </p>
                <p className="text-sm text-red_600 font-semibold">
                  - {dept} GEL
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-1">
            <Link to={"ballance-top-up"}>
              <div className="flex items-center gap-2 bg-teal_600_b2 rounded-lg p-2 border-2 border-teal_600">
                <BiWallet className="w-6 h-6 text-white" />
                <p className="text-xs text-white font-bold">
                  {t("my_balance")}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`p-5 fixed top-0 left-0 w-screen h-screen overflow-auto transition-all ease-linear duration-300 z-50  
            ${menuOpend ? "translate-x-[0%]" : "translate-x-[-100%]"}`}
      >
        <div className="rounded-lg w-full bg-white shadow-bs1 md:w-6/12 sm:w-full md:max-h-[90vh] overflow-auto scrollbar">
          <div className="relative w-full flex flex-col ">
            <div className="flex items-center justify-between w-full p-3">
              <div className="flex gap-1 items-center w-full">
                <div
                  className="flex h-10 w-10 items-center justify-center rounded-full  bg-white_A700 border-2 
                            border-solid border-teal_600 p-[5px]"
                >
                  <BsBox className="w-full h-full text-teal_600" />
                </div>
                <div className="flex flex-col items-start justify-start w-auto">
                  <p className="text-sm font-semibold">
                    {user.company_id === null ? (
                      <>
                        {user.fname_eng} {user.lname_eng}
                      </>
                    ) : (
                      <>{user.cname_eng}</>
                    )}
                  </p>
                  <p className="text-teal_600 text-sm font-semibold">
                    ID: {user.id}
                  </p>
                </div>
              </div>
              <div
                className="w-8 h-8 bg-teal_600_b2 border border-teal_600 p-1 rounded-full cursor-pointer shrink-0"
                onClick={() => {
                  setMenuOpend(false);
                }}
              >
                <BiChevronLeft className="w-full h-full text-white" />
              </div>
            </div>
            {data.map((item) => {
              return (
                <div
                  key={item.title}
                  className="p-3 flex flex-col justify-start w-full border-t border-gray-200"
                >
                  <h2 className="text-sm font-semibold">
                    {item.title.includes("/")
                      ? t(item.title.split("/")[0]) +
                        " / " +
                        t(item.title.split("/")[1])
                      : t(item.title)}
                  </h2>
                  {item.menuItem.map((singleLink, index) => {
                    return (
                      <Link
                        key={index}
                        to={singleLink.link}
                        onClick={() => {
                          setMenuOpend(false);
                        }}
                      >
                        <div
                          className={`py-2 hover:bg-teal_600 hover:px-2 flex flex-row gap-2 items-center justify-start left-[0] mt-1  
                            rounded-xl group w-full ${
                              singleLink.active && "bg-teal_600 px-2"
                            }`}
                        >
                          <singleLink.Icon
                            className={`w-4 h-4 group-hover:text-white
                                    ${
                                      singleLink.active
                                        ? "text-white"
                                        : "text-teal_600"
                                    }`}
                          />
                          <p
                            className={`text-xs group-hover:text-white font-semibold
                           ${
                             singleLink.active ? "text-white" : "text-teal_600"
                           }`}
                          >
                            {t(singleLink.title)}
                          </p>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
            <div className="p-4 border-t border-gray-200">
              <a target="_blank" href={terms}>
                <p className="text-sm text-gray-500 underline">
                  {t("terms_agreement")}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Messenger />
    </div>
  );
};

export default UserMenuPhone;
