import { useTranslation } from "react-i18next";
import InstructionsSection from "../../components/landingSections/InstructionsSection/InstructionsSection";
import AboutUsSection from "../../components/landingSections/aboutUsSection/AboutUsSection";
import BannerSection from "../../components/landingSections/bannerSection/BannerSection";
import CalculatorSection from "../../components/landingSections/calculatorSections/CalculatorSection";
import ContactSection from "../../components/landingSections/contactSection/ContactSection";
import FaqSection from "../../components/landingSections/faqSection/FaqSection";
import FirstSection from "../../components/landingSections/firstSection/FirstSection";
import FlightsSection from "../../components/landingSections/flightsSection/FlightsSection";
import ServicesSection from "../../components/landingSections/servicesSection/ServicesSection";
import WorkingHoursCard from "../../components/workinghours/WorkingHoursCard";

const Landing = ({ menuRefs }) => {
  return (
    <div>
      <FirstSection />
      <div className="flex flex-col gap-10 pb-10">
        <BannerSection />
        <ServicesSection />
        <CalculatorSection />
      </div>
    </div>
  );
};

export default Landing;
