import MainRouter from "./MainRouter";
import { MainContext } from "./components/MainContext/MainContext";
import { useEffect } from "react";
import logo from "./favicon.ico";

function App() {

  // Function to clear complete cache data
  const clearCacheData = () => {
    const oldToken = localStorage.getItem("MGL_TOKEN")
    const oldTokenAdm = localStorage.getItem("MGL_TOKEN_ADM")
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.setItem("MGL_TOKEN", oldToken)
    localStorage.setItem("MGL_TOKEN_ADM", oldTokenAdm)
  };

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/ico';
    link.rel = 'icon';
    link.href = logo;
    document.head.appendChild(link);

    clearCacheData()
  }, []);

  return <div className="App">
    <MainContext>
      <MainRouter />
    </MainContext>
  </div>;
}

export default App;
